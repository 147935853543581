// eslint-disable-next-line no-restricted-imports
import {on} from 'delegated-events'

on('click', '.js-preview-message', function (event) {
  const inputElement =
    document.querySelector<HTMLInputElement>('#custom_message_input_value') ||
    document.querySelector<HTMLTextAreaElement>('#custom_message_input_value')
  const message = inputElement!.value
  document.querySelector<HTMLInputElement>('#announcement_preview_value')!.value = message

  const userDismissibleCheckbox = document.querySelector<HTMLInputElement>(
    '#custom_messages_user_dismissible_value'
  )!.value
  document.querySelector<HTMLInputElement>('#announcement_preview_user_dismissible')!.value = userDismissibleCheckbox

  const button = event.currentTarget as HTMLButtonElement
  button.form!.submit()
})
