// PATs v2: Token summary display behavior

// eslint-disable-next-line no-restricted-imports
import {observe} from 'selector-observer'
// eslint-disable-next-line no-restricted-imports
import {on} from 'delegated-events'
import {toggleMetadataSelectionMenu} from './integrations'

const enum RepoSelectionType {
  None = 'none',
  All = 'all',
  Selected = 'selected'
}

function updateElText(selector: string, text: string) {
  const el = document.querySelector<HTMLElement>(selector)!
  if (el) el.textContent = text
}

function elCount(selector: string): number {
  return document.querySelectorAll<HTMLInputElement>(selector).length
}

function updateName() {
  const nameInputEl = document.querySelector<HTMLInputElement>('#user_programmatic_access_name')!
  updateElText('#token-summary [data-token-summary="name"]', nameInputEl.value)
}

function updatePermissionCount(permissionType: string) {
  const permissionCount = elCount(
    `[data-${permissionType}-permission] .js-permissions-selector [id^=integration_permission_]:checked:not([data-permission="none"])`
  )
  updateElText(`#token-summary [data-token-summary="${permissionType}-permission-count"]`, String(permissionCount))
}

function updateRepositoryCount() {
  const repoSelectorRadio = document.querySelector<HTMLInputElement>('.js-installation-repositories-radio:checked')
  const selectionType = repoSelectorRadio ? repoSelectorRadio.value : RepoSelectionType.None

  if (selectionType === RepoSelectionType.Selected) {
    const repositoryCount = elCount('.js-selected-repository-field')
    updateElText('#token-summary [data-token-summary="repository-count"]', String(repositoryCount))
  } else {
    updateElText('#token-summary [data-token-summary="repository-count"]', selectionType)
  }
}

function updateExpiration() {
  const expirationValue = document.querySelector<HTMLSelectElement>('.js-default-token-expiration-select')!.value
  if (expirationValue === 'none') {
    updateElText('#token-summary [data-token-summary="expiration"]', 'never.')
  } else if (expirationValue === 'custom') {
    const customDate = document.querySelector<HTMLInputElement>('#user_programmatic_access_custom_expires_at')!.value
    // Setting the time to midnight because otherwise the default of midnight UTC might output the prior date based on timezone offset
    const dateString = customDate ? formatDateString(new Date(`${customDate}T00:00:00`)) : 'an unselected custom date'
    updateElText('#token-summary [data-token-summary="expiration"]', `on ${dateString}.`)
  } else {
    const dateString = calculateDate(expirationValue)
    updateElText('#token-summary [data-token-summary="expiration"]', `on ${dateString}.`)
  }
}

function formatDateString(date: Date): string {
  return new Intl.DateTimeFormat('en-US', {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
    year: 'numeric'
  })
    .format(date)
    .replace(/,(?=\s\d{4})/, '')
}

function calculateDate(expirationValue: string): string {
  const date = new Date()
  date.setDate(date.getDate() + Number(expirationValue))
  return formatDateString(date)
}

function resetPermissions() {
  const inputs = document.querySelectorAll<HTMLInputElement>('[id^=integration_permission_][data-permission="none"]')

  for (const input of inputs) {
    input.checked = true
  }

  toggleMetadataSelectionMenu()
  setInitialValues()
}

function toggleRepoPermissionVisibility() {
  const selectionType = document.querySelector<HTMLInputElement>('.js-installation-repositories-radio:checked')!.value
  const permissionSection = document.querySelector<HTMLElement>(
    '.js-integration-permissions-selector section[data-repository-permission]'
  )!

  if (selectionType === RepoSelectionType.None) {
    permissionSection.hidden = true
    updateElText(`#token-summary [data-token-summary="repository-permission-count"]`, String(0))
  } else {
    permissionSection.hidden = false
    updatePermissionCount('repository')
  }
}

function displayGrantForm() {
  const grantForm = document.querySelector<HTMLElement>('#js-grant-form')!
  const grantSummary = document.querySelector<HTMLElement>('#js-grant-summary')!
  grantSummary.hidden = true
  grantForm.hidden = false

  const formActions = document.querySelectorAll<HTMLElement>('.js-grant-form-action')!
  for (const action of formActions) {
    action.hidden = false
  }

  const summaryActions = document.querySelectorAll<HTMLElement>('.js-grant-summary-action')!
  for (const action of summaryActions) {
    action.hidden = true
  }
}

function displayGrantSummary() {
  const grantForm = document.querySelector<HTMLElement>('#js-grant-form')!
  const grantSummary = document.querySelector<HTMLElement>('#js-grant-summary')!
  grantForm.hidden = true
  grantSummary.hidden = false

  const summaryActions = document.querySelectorAll<HTMLElement>('.js-grant-summary-action')!
  for (const action of summaryActions) {
    action.hidden = false
  }

  const formActions = document.querySelectorAll<HTMLElement>('.js-grant-form-action')!
  for (const action of formActions) {
    action.hidden = true
  }
}

function displayTokenForm() {
  const displayFields = document.querySelectorAll<HTMLElement>('.js-token-display-field')!
  for (const field of displayFields) {
    field.hidden = true
  }

  const formFields = document.querySelectorAll<HTMLElement>('.js-token-form-field')!
  for (const field of formFields) {
    field.hidden = false
  }
}

function hideTokenForm() {
  const formFields = document.querySelectorAll<HTMLElement>('.js-token-form-field')!
  for (const field of formFields) {
    field.hidden = true
  }

  const displayFields = document.querySelectorAll<HTMLElement>('.js-token-display-field')!
  for (const field of displayFields) {
    field.hidden = false
  }
}

function setInitialValues() {
  updatePermissionCount('repository')
  updatePermissionCount('user')
  updatePermissionCount('organization')
  updateRepositoryCount()
}

observe('.js-user-programmatic-access-form .js-integrations-install-repo-selection', el => {
  // observes when repository selections are added to the DOM
  // handles the case when user toggles back to select repositories displaying previous selections
  const selectedRepoListObserver = new MutationObserver(updateRepositoryCount)
  // observe subtree from element that is never removed from the dom to catch all changes
  selectedRepoListObserver.observe(el, {childList: true, subtree: true})
})

observe('.js-user-programmatic-access-form', setInitialValues)
on(
  'change',
  '.js-user-programmatic-access-form [data-repository-permission] .js-permissions-selector [id^=integration_permission_]',
  () => updatePermissionCount('repository')
)
on(
  'change',
  '.js-user-programmatic-access-form [data-user-permission] .js-permissions-selector [id^=integration_permission_]',
  () => updatePermissionCount('user')
)
on(
  'change',
  '.js-user-programmatic-access-form [data-organization-permission] .js-permissions-selector [id^=integration_permission_]',
  () => updatePermissionCount('organization')
)
on('change', '.js-user-programmatic-access-form .js-installation-repositories-radio', () => {
  resetPermissions()
  toggleRepoPermissionVisibility()
})
on('change', '.js-user-programmatic-access-form #user_programmatic_access_name', updateName)
on('change', '.js-user-programmatic-access-form .js-default-token-expiration-select', updateExpiration)
on('change', '.js-user-programmatic-access-form #user_programmatic_access_custom_expires_at', updateExpiration)

on('click', '#js-token-action-edit', displayTokenForm)
on('click', '#js-token-action-cancel', hideTokenForm)
on('click', '#js-token-action-save', function ({currentTarget}) {
  currentTarget.classList.add('disabled')
  currentTarget.textContent = 'Updating'
})

on('click', '.js-toggle-grant-form', displayGrantForm)
on('click', '.js-toggle-grant-summary', displayGrantSummary)
